body {
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.StripeElement {
  width: 100%;
  line-height: 2;
}

.stripeContainer {
  width: 40%;
  margin: 0 auto;
}

.width-50 {
  width: 50%;
  margin: 0 auto;
}

.container {
  position: relative;
  width: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(128, 128, 128, 0.03);
}

.centered {
  position: relative;
  width: 50%;
  margin: 0 auto;
}

.sale h1 {
  font-size: 2em; /* Adjust size as needed */
  color: #ff0000; /* Red color */
  font-weight: bold; /* Make it bold */
 /* text-shadow: 2px 2px 4px #000000; /* Shadow effect */
 /* animation: blink 1s linear infinite; /* Blinking effect */
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


@keyframes blink {
  0% {opacity: 1;}
  50% {opacity: 0.5;}
  100% {opacity: 1;}
}